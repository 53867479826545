import { Button } from "components/editor/components";
import { ExtractionStatus, InstantDraftStatus } from "components/copilot/CopilotSchemaTypes";
import { setHighlightedElementId } from "store/reducers/extract/CurrentExtractionReducer";
import { useTrackUserMetric } from "utils/metrics";
import { clearExtractState, setExtractState } from "store/reducers/extract/CurrentExtractionReducer";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Chip from "components/atoms/chip";
import Stepper from "components/organisms/stepper";
import tw from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlagSet } from "types/FeatureFlagSet";
import useImportExtraction from "hook/doc-view/useImportExtraction";
import { StepValue } from "../types";
import { EXTRACTION_STATUS_TO_META } from "../../constants";
import { useExtractionSteps, useExtractionStepsToMeta } from "./hooks";

const StepManager = () => {
  const flags = useFlags<FeatureFlagSet>();
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const viewSummary = useAppSelector((store) => store.currentExtractionState.viewSummary);
  const isExtractionComplete = extraction?.status === ExtractionStatus.Completed;
  const isInstantDraftComplete = extraction?.instantDraftConfig?.status === InstantDraftStatus.Done;
  const { extractSteps, groupedSteps } = useExtractionSteps(
    flags.instantDraftFromGeneration,
    extraction?.step,
    isInstantDraftComplete || isExtractionComplete
  );
  const { updateExtractionStep } = useExtractionOperations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const trackUserEvent = useTrackUserMetric();
  const { importExtraction, isImporting } = useImportExtraction();
  const STEP_TO_META = useExtractionStepsToMeta(flags.instantDraftFromGeneration);

  const handleUpdateExtractionStep = useCallback(
    (extractionId: string, step: StepValue) => {
      dispatch(setHighlightedElementId(""));
      updateExtractionStep(extractionId, step);
    },
    [dispatch, updateExtractionStep]
  );

  if (!extraction) return null;

  const nextStep = STEP_TO_META[extraction.step]?.nextStep;
  const prevStep = STEP_TO_META[extraction.step]?.prevStep;

  const isDisabled = extraction.status === ExtractionStatus.Extracting;
  const showGenerateDraftButton =
    extraction.status !== ExtractionStatus.Extracting && extraction.step === StepValue.Review && !viewSummary;

  return (
    <div className="p-1 pb-0">
      <div className="relative border p-2 flex justify-between items-center border-gray-lightest rounded bg-white shadow">
        <div className="flex-1 flex justify-start">
          <button
            className="flex items-center h-[34px] rounded-md text-md font-lg px-3 duration-150 hover:text-black hover:bg-slate-200/[0.5]"
            onClick={() => {
              trackUserEvent("Generate: Exit Generation", {
                analysis_id: extraction.id,
                solicitation_type: extraction.solicitation_type,
              });
              navigate({
                pathname: "/dashboard/contracts/details",
                search: searchParams.toString(),
              });
              dispatch(clearExtractState());
            }}
          >
            <ArrowLeft size={16} />
          </button>
        </div>
        <Stepper<StepValue>
          disabled={isDisabled}
          activeStepItem={groupedSteps[extraction.step]}
          steps={extractSteps}
          onStepSelect={(step) => handleUpdateExtractionStep(extraction.id, step.value)}
        />
        <div className="flex items-center gap-2 flex-1 justify-end">
          {extraction.status === ExtractionStatus.Completed && (
            <>
              <Chip
                styles={tw`py-1 mr-1`}
                variant="secondary"
                colors={{
                  primaryColor: EXTRACTION_STATUS_TO_META[extraction.status].colors.primary,
                  secondaryColor: EXTRACTION_STATUS_TO_META[extraction.status].colors.secondary,
                }}
              >
                <div>{EXTRACTION_STATUS_TO_META[extraction.status].label}</div>
              </Chip>
              {!flags.instantDraftFromGeneration && (
                <button
                  onClick={() => dispatch(setExtractState({ viewSummary: true }))}
                  className="rounded bg-transparent px-4 py-1.5 text-xs font-medium text-action duration-150 hover:bg-action-secondary"
                >
                  View Summary
                </button>
              )}
            </>
          )}
          {extraction.status !== ExtractionStatus.Completed && (nextStep || prevStep) && (
            <>
              {prevStep && (
                <Button
                  disabled={isDisabled}
                  onClick={() => handleUpdateExtractionStep(extraction.id, prevStep)}
                  variant="outline"
                  size="sm/md"
                >
                  Back
                </Button>
              )}
              {nextStep &&
                (extraction.step !== StepValue.Generate || isInstantDraftComplete || isExtractionComplete) && (
                  <Button
                    disabled={isDisabled}
                    className="gap-2 !pr-3"
                    onClick={() => handleUpdateExtractionStep(extraction.id, nextStep)}
                    variant="primary"
                    size="sm/md"
                  >
                    Continue
                    <ArrowRight size={14} className="stroke-[2.5]" />
                  </Button>
                )}
              {showGenerateDraftButton && !flags.instantDraftFromGeneration && (
                <div className="flex justify-center">
                  <Button
                    onClick={importExtraction}
                    loading={isImporting}
                    disabled={isImporting}
                    variant="primary"
                    size="sm/md"
                  >
                    Generate
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepManager;
